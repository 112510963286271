import { CircularProgress } from "@mui/material";
import { Component } from "react";
import { Subscription } from "rxjs";

import { KeycloakUser } from "../../services/keycloak-service";
import { GameState } from "../../types/entities/game-state";
import { Ground } from "../../types/entities/ground";
import { Match } from "../../types/entities/match";
import { MatchFormat } from "../../types/entities/match-format";
import {
  humanReadablePushBrackets,
  pushBracketClassNames,
} from "../../types/enums/push-bracket";
import {
  AdminPreferences,
  ToggleType,
  getFavouredMatchSpecificSettingsIndex,
  isToggleEnabled,
} from "../../types/preferences/admin-preferences";
import { UserPreferences } from "../../types/preferences/preferences";
import { SimulatorRoute } from "../../types/route-helpers";
import { services, showMessage } from "../../types/services";
import { NodeHealth } from "../../types/simulator/node-health";
import { GroundFormExplanation } from "../../types/stats/form-explanation";
import { GroundStatsWrapper } from "../../types/stats/ground-stats";
import { UUID } from "../../types/uuid";
import AppAlert from "../common-components/app-alert";
import {
  noHealthyNodes,
  noSimulationsAllowed,
  observableFrom,
} from "../component-utils";
import { StatsLoadingPage } from "../stats-loading-page/stats-loading-page";

import GroundFormExplanationDisplay from "./ground-form-explanation-display";
import { GroundPowerplayStats } from "./ground-powerplay-stats";
import { GroundPushStats } from "./ground-push-stats";
import { GroundStatsGenerationButtons } from "./ground-stats-generation-buttons";
import { GroundStatsSelector } from "./ground-stats-selector";
import { GroundStatsSurgeSelector } from "./ground-stats-surge-selector";
import { GroundStrikeRateAndWicketPercentByPush } from "./ground-strike-rate-and-wicket-percent-by-push";
import { GroundStrikeRateAndWicketPercentFlatAdjustment } from "./ground-strike-rate-and-wicket-percent-flat-adjustment";
import { GroundStrikeRateAndWicketPercentStats } from "./ground-strike-rate-and-wicket-percent-stats";
import { GroundSwingStats } from "./ground-swing-stats";

interface State {
  ground: Ground;
  gameState: GameState;
  matchFormat: MatchFormat;
  userPreferences: UserPreferences;
  currentStats: GroundStatsWrapper;
  recentGroundStats: GroundStatsWrapper[];
  loading: boolean;
  renaming: boolean;
  nameValid: boolean;
  comparedUserName: string;
  comparedStats: GroundStatsWrapper;
  match: Match;
  nodeHealth: NodeHealth[];
  latestVersion: string;
  formExplanation: GroundFormExplanation;
  adminPreferences: AdminPreferences;
}

export type ValidPropertyName = "nameValid";

export class GroundPage extends Component<{}, State> {
  private subscriptions: Subscription[];
  private static readonly DEFAULT_STATE = {
    gameState: null,
    matchFormat: null,
    ground: null,
    userPreferences: null,
    originalStats: null,
    currentStats: null,
    teamStats: new Map(),
    recentGroundStats: null,
    loading: false,
    renaming: false,
    nameValid: true,
    comparedUserName: null,
    comparedStats: null,
    match: null,
    nodeHealth: null,
    latestVersion: null,
    formExplanation: null,
    adminPreferences: null,
  };

  constructor(props) {
    super(props);
    this.subscriptions = [];
    this.state = {
      ...GroundPage.DEFAULT_STATE,
    };
  }

  componentDidMount() {
    this.subscriptions.push(
      services.currentGameService.groundSubject.subscribe((ground: Ground) =>
        this.setState({ ground })
      )
    );

    this.subscriptions.push(
      services.currentGameService.currentStateSubject.subscribe(
        (currentState: GameState) => this.setState({ gameState: currentState })
      )
    );

    this.subscriptions.push(
      services.currentGameService.currentMatchSubject.subscribe(
        (match: Match) => this.setState({ match }, () => this.updateGround())
      )
    );

    this.subscriptions.push(
      services.currentGameService.currentMatchFormatSubject.subscribe(
        (matchFormat: MatchFormat) => this.setState({ matchFormat })
      )
    );

    this.subscriptions.push(
      services.groundStatsService.groundStatsSubject.subscribe(
        (currentStats: GroundStatsWrapper) => this.setState({ currentStats })
      )
    );

    this.subscriptions.push(
      services.groundStatsService.comparedGroundStatsSubject.subscribe(
        (comparedStats: GroundStatsWrapper) => this.setState({ comparedStats })
      )
    );

    this.subscriptions.push(
      services.userService.userPreferencesSubject.subscribe(
        (userPreferences: UserPreferences) => this.setState({ userPreferences })
      )
    );

    this.subscriptions.push(
      services.keycloakService.comparedUserSubject.subscribe(
        (comparedUser: KeycloakUser) =>
          this.setState({
            comparedUserName: !!comparedUser ? comparedUser.name : null,
          })
      )
    );

    this.subscriptions.push(
      services.simulationService.latestNodeHealthSubject.subscribe(
        (nodes: NodeHealth[]) => {
          if (nodes) {
            this.setState({
              nodeHealth: nodes,
            });
          }
        }
      )
    );

    this.subscriptions.push(
      services.simulationService.latestNodeVersionSubject.subscribe(
        (latestVersion: string) => {
          this.setState({ latestVersion: latestVersion });
        }
      )
    );

    this.subscriptions.push(
      services.userService.adminPreferencesSubject.subscribe(
        (adminPreferences: AdminPreferences) =>
          this.setState({ adminPreferences })
      )
    );
  }

  componentWillUnmount(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  private updateGround() {
    if (!!this.state.match) {
      this.setState({ loading: true }, () => {
        const groundId: UUID = this.state.match.groundId;
        services.groundStatsService
          .getAllForGround(groundId, this.state.match.matchId)
          .then((stats: GroundStatsWrapper[]) => {
            this.setState({
              loading: false,
              recentGroundStats: stats,
            });
          })
          .catch((reason) => {
            showMessage(
              `Could not load ground recent stats: ${reason}`,
              "error"
            );
          });
      });
    }
  }

  private groundStatsValid(): boolean {
    return this.state.nameValid;
  }

  private saveStats() {
    services.groundStatsService
      .updateGroundStats(this.state.currentStats)
      .then(() => showMessage("Ground stats saved", "success"));
  }

  private updateStats(currentStats: GroundStatsWrapper): void {
    this.setState({ ...this.state, currentStats }, () =>
      this.groundStatsValid() ? this.saveStats() : {}
    );
  }

  private splitPowerplay(): boolean {
    if (!this.state?.adminPreferences) {
      return false;
    }

    return isToggleEnabled(
      this.state?.adminPreferences,
      ToggleType.SPLIT_POWERPLAY_AND_NON_POWERPLAY
    );
  }

  private useNewPowerplayModule(): boolean {
    if (!this.state?.adminPreferences || !this.state?.match) {
      return false;
    }

    const index = getFavouredMatchSpecificSettingsIndex(
      this.state.adminPreferences,
      this.state.match
    );

    if (index === -1) {
      return this.state.adminPreferences.useNewPowerplayModule;
    }

    return this.state.adminPreferences.matchSpecificSettings[index]
      .useNewPowerplayModule;
  }

  public render() {
    return (
      <SimulatorRoute>
        <div className="full-push-background-light with-navbar">
          {noSimulationsAllowed(
            this.state.match,
            this.state.userPreferences
          ) && (
            <AppAlert>
              Check if you have at least 1 simulation enabled in settings and
              that the simulator is enabled.
            </AppAlert>
          )}
          {noHealthyNodes(this.state.nodeHealth, this.state.latestVersion) && (
            <AppAlert severity="warning">
              You have no nodes, please set them up to run the simulator
            </AppAlert>
          )}
          <div className="page-title-and-buttons">
            <div className="page-title">Ground Settings</div>

            {!!this.state.currentStats &&
              !!this.state.recentGroundStats &&
              !!this.state.match &&
              !!this.state.ground && (
                <GroundStatsGenerationButtons
                  ground={this.state.ground}
                  match={this.state.match}
                  matchFormat={this.state.matchFormat}
                  currentStats={this.state.currentStats}
                  comparedStats={this.state.comparedStats}
                  comparedUsername={this.state.comparedUserName}
                  setLoadingThenExecute={(f: () => void) =>
                    this.setState({ loading: true }, f)
                  }
                  onFailure={() => this.setState({ loading: false })}
                  onUpdate={(currentStats: GroundStatsWrapper) =>
                    this.setState({ loading: false, currentStats }, () =>
                      this.saveStats()
                    )
                  }
                  onFormExplanation={(
                    formExplanation: GroundFormExplanation
                  ) => {
                    this.setState({ loading: false, formExplanation });
                  }}
                  matchType={this.state.match.matchType}
                  userPreferences={this.state.userPreferences}
                />
              )}
          </div>
          <div className="ground-page">
            {!!this.state.gameState &&
              this.state.gameState.initialStatsLoaded && (
                <div className="ground-display">
                  {this.state.loading && <CircularProgress />}
                  {!this.state.loading && !!this.state.ground && (
                    <div>
                      <div className="ground-stats-title">
                        {this.state.ground.name}
                      </div>
                      {!!this.state.currentStats &&
                        !!this.state.recentGroundStats && (
                          <div className="ground-stats-body">
                            {!!this.state.formExplanation && (
                              <GroundFormExplanationDisplay
                                formExplanation={this.state.formExplanation}
                              />
                            )}

                            <GroundStatsSelector
                              ground={this.state.ground}
                              currentStats={this.state.currentStats}
                              recentGroundStats={observableFrom(
                                this.state.recentGroundStats
                              )}
                              onUpdate={(currentStats, nameValid) =>
                                this.setState({ nameValid }, () =>
                                  this.updateStats(currentStats)
                                )
                              }
                            />

                            <div className="ground-stats-by-push">
                              {[
                                {
                                  index: 0,
                                  title: humanReadablePushBrackets.BLOCKING,
                                  classes: pushBracketClassNames.BLOCKING,
                                },
                                {
                                  index: 1,
                                  title: humanReadablePushBrackets.DEFENSIVE,
                                  classes: pushBracketClassNames.DEFENSIVE,
                                },
                                {
                                  index: 2,
                                  title: humanReadablePushBrackets.NORMAL,
                                  classes: pushBracketClassNames.NORMAL,
                                },
                                {
                                  index: 3,
                                  title: humanReadablePushBrackets.PUSHING,
                                  classes: pushBracketClassNames.PUSHING,
                                },
                                {
                                  index: 4,
                                  title: humanReadablePushBrackets.FULL_PUSH,
                                  classes: pushBracketClassNames.FULL_PUSH,
                                },
                              ].map(({ index, title, classes }) => (
                                <GroundStrikeRateAndWicketPercentByPush
                                  key={index}
                                  currentStats={this.state.currentStats}
                                  comparedUserName={this.state.comparedUserName}
                                  comparedStats={this.state.comparedStats}
                                  index={index}
                                  title={title}
                                  classes={classes}
                                  userPreferences={this.state.userPreferences}
                                  onUpdate={(currentStats) =>
                                    this.updateStats(currentStats)
                                  }
                                />
                              ))}
                            </div>

                            <div className="ground-stats-miscellaneous">
                              {this.splitPowerplay() ? (
                                <>
                                  <GroundStrikeRateAndWicketPercentStats
                                    currentStats={this.state.currentStats}
                                    comparedStats={this.state.comparedStats}
                                    comparedUserName={
                                      this.state.comparedUserName
                                    }
                                    title="Powerplay"
                                    strikeRateProperty="powerplayStrikeRateBias"
                                    wicketPercentProperty="powerplayWicketBias"
                                    confidenceProperty="powerplayConfidence"
                                    onUpdate={(currentStats) =>
                                      this.updateStats(currentStats)
                                    }
                                    percentDistributionBiasProperty="globalPercentDistributionBiasData"
                                    percentDistributionBiasPropertyName="Global"
                                    userPreferences={this.state.userPreferences}
                                  />
                                  <GroundStrikeRateAndWicketPercentStats
                                    currentStats={this.state.currentStats}
                                    comparedStats={this.state.comparedStats}
                                    comparedUserName={
                                      this.state.comparedUserName
                                    }
                                    title="Non Powerplay"
                                    strikeRateProperty="nonPowerplayStrikeRateBias"
                                    wicketPercentProperty="nonPowerplayWicketBias"
                                    confidenceProperty="nonPowerplayConfidence"
                                    onUpdate={(currentStats) =>
                                      this.updateStats(currentStats)
                                    }
                                    percentDistributionBiasProperty="globalPercentDistributionBiasData"
                                    percentDistributionBiasPropertyName="Global"
                                    userPreferences={this.state.userPreferences}
                                  />
                                </>
                              ) : (
                                <GroundStrikeRateAndWicketPercentStats
                                  currentStats={this.state.currentStats}
                                  comparedStats={this.state.comparedStats}
                                  comparedUserName={this.state.comparedUserName}
                                  title="Global"
                                  strikeRateProperty="globalStrikeRateBias"
                                  wicketPercentProperty="globalWicketBias"
                                  confidenceProperty="globalConfidence"
                                  onUpdate={(currentStats) =>
                                    this.updateStats(currentStats)
                                  }
                                  percentDistributionBiasProperty="globalPercentDistributionBiasData"
                                  percentDistributionBiasPropertyName="Global"
                                  userPreferences={this.state.userPreferences}
                                />
                              )}

                              <GroundStrikeRateAndWicketPercentStats
                                currentStats={this.state.currentStats}
                                comparedStats={this.state.comparedStats}
                                comparedUserName={this.state.comparedUserName}
                                title="Against Pace"
                                strikeRateProperty="paceStrikeRateBias"
                                wicketPercentProperty="paceWicketBias"
                                confidenceProperty="paceConfidence"
                                onUpdate={(currentStats) =>
                                  this.updateStats(currentStats)
                                }
                                percentDistributionBiasProperty={
                                  "pacePercentDistributionBiasData"
                                }
                                percentDistributionBiasPropertyName={"Pace"}
                                userPreferences={this.state.userPreferences}
                              />
                              <GroundStrikeRateAndWicketPercentStats
                                currentStats={this.state.currentStats}
                                comparedStats={this.state.comparedStats}
                                comparedUserName={this.state.comparedUserName}
                                title="Against Spin"
                                strikeRateProperty="spinStrikeRateBias"
                                wicketPercentProperty="spinWicketBias"
                                confidenceProperty="spinConfidence"
                                onUpdate={(currentStats) =>
                                  this.updateStats(currentStats)
                                }
                                percentDistributionBiasProperty={
                                  "spinPercentDistributionBiasData"
                                }
                                percentDistributionBiasPropertyName={"Spin"}
                                userPreferences={this.state.userPreferences}
                              />
                              {!this.useNewPowerplayModule() && (
                                <GroundPowerplayStats
                                  currentStats={this.state.currentStats}
                                  comparedUserName={this.state.comparedUserName}
                                  comparedStats={this.state.comparedStats}
                                  onUpdate={(currentStats) =>
                                    this.updateStats(currentStats)
                                  }
                                />
                              )}
                              <GroundPushStats
                                currentStats={this.state.currentStats}
                                comparedUserName={this.state.comparedUserName}
                                comparedStats={this.state.comparedStats}
                                onUpdate={(currentStats) =>
                                  this.updateStats(currentStats)
                                }
                              />
                              <GroundSwingStats
                                currentStats={this.state.currentStats}
                                comparedUserName={this.state.comparedUserName}
                                comparedStats={this.state.comparedStats}
                                onUpdate={(currentStats) =>
                                  this.updateStats(currentStats)
                                }
                              />

                              <GroundStatsSurgeSelector
                                currentStats={this.state.currentStats}
                                comparedUserName={this.state.comparedUserName}
                                comparedStats={this.state.comparedStats}
                                onUpdate={(currentStats) =>
                                  this.updateStats(currentStats)
                                }
                                innings={1}
                                strikeRateProperty="surgeStrikeRateBiasInnings1"
                                wicketProperty="surgeWicketBiasInnings1"
                              />

                              <GroundStatsSurgeSelector
                                currentStats={this.state.currentStats}
                                comparedUserName={this.state.comparedUserName}
                                comparedStats={this.state.comparedStats}
                                onUpdate={(currentStats) =>
                                  this.updateStats(currentStats)
                                }
                                innings={2}
                                strikeRateProperty="surgeStrikeRateBiasInnings2"
                                wicketProperty="surgeWicketBiasInnings2"
                              />

                              <GroundStrikeRateAndWicketPercentFlatAdjustment
                                currentStats={this.state.currentStats}
                                comparedUserName={this.state.comparedUserName}
                                comparedStats={this.state.comparedStats}
                                onUpdate={(currentStats) =>
                                  this.updateStats(currentStats)
                                }
                              />
                            </div>
                          </div>
                        )}
                    </div>
                  )}
                </div>
              )}
            {!!this.state.gameState &&
              !this.state.gameState.initialStatsLoaded && <StatsLoadingPage />}
            {!this.state.gameState && (
              <div className="page-title">No match selected</div>
            )}
          </div>
        </div>
      </SimulatorRoute>
    );
  }
}
